import { message } from 'ant-design-vue'
import Vue from 'vue'

Vue.use(message)

// 过滤日志接口页面不提示错误信息
const filterRequestUrl = ['/statistics', '/cms/url/page/record']

export default function ({ $axios, redirect, env, route, store, req, isDev, isClient, $winstonLog }) {
  $axios.onRequest((config) => {
    if (!config.params) {
      config.params = {}
    }

    if (store.state.siteId) {
      // console.log(store.state.siteId)
      config.params.siteId = store.state.siteId
    }

    if (store.state.siteName) {
      // console.log(store.state.siteName)
      config.params.siteName = store.state.siteName
      //  config.params.siteName = 'g-test.163yun.com'
    }

    if (config.method === 'get') {
      config.params.t = new Date().getTime()
    }
  })

  // $axios.onResponse((response) => {
  //   if ($winstonLog) {
  //     $winstonLog.info(`[${response.status}] ${response.request.path}`)
  //   }
  // })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    if (!(error.config.params && error.config.params.noAuth)) {
      if (code === 401) {
        // console.log(process.client)
        if (process.client) {
          location.href = `${env.passportUrl}/login?referrer=${encodeURIComponent(location.href)}`
        } else {
          redirect(`${env.passportUrl}/login?referrer=${encodeURIComponent((isDev ? 'http://' : 'https://') + req.headers.host + route.fullPath)}`)
        }
        if ($winstonLog) {
          $winstonLog.info(`[${code}] ${error.response.request.path}`)
        }
      } else {
        const requestUrl = error.response.config.url
        if ($winstonLog) {
          $winstonLog.info(`[${code}] ${error.response.request.path} ${error.response.data.message}`)
        }
        if (filterRequestUrl.indexOf(requestUrl) > -1) {
          return
        }
        message.error(error.response.data.message)
      }
    }
  })
}
