var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"confirm-loading":_vm.modalLoading,"title":"页面设置","ok-text":"确定","cancel-text":"取消"},on:{"cancel":function () { _vm.$emit('cancel') },"ok":function () { _vm.$emit('create') }}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{attrs:{"label":"页面名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'desc',
          {
            initialValue: _vm.info.desc || '',
            rules: [{ required: true, message: '请输入页面名称' }]
          }
        ]),expression:"[\n          'desc',\n          {\n            initialValue: info.desc || '',\n            rules: [{ required: true, message: '请输入页面名称' }]\n          }\n        ]"}],attrs:{"placeholder":"XXX页"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":"URL"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'url',
          {
            initialValue: _vm.info.url || '',
            rules: [
              { required: true, message: '请输入URL' },
              { pattern: /^[0-9a-z_\/]{1,}$/, message: '请输入英文、数字或_' }
            ]
          }
        ]),expression:"[\n          'url',\n          {\n            initialValue: info.url || '',\n            rules: [\n              { required: true, message: '请输入URL' },\n              { pattern: /^[0-9a-z_\\/]{1,}$/, message: '请输入英文、数字或_' }\n            ]\n          }\n        ]"}],attrs:{"addon-before":("https://" + (_vm.siteInfo.subdomain) + (_vm.siteInfo.domainName))}}),_vm._v(" "),_c('span',{attrs:{"slot":"extra"},slot:"extra"},[_vm._v("可将上述链接复制到您指定的页面按钮链接中"),_c('a',{on:{"click":_vm.copy}},[_vm._v("复制链接")])])],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":"浏览器标题title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            initialValue: _vm.info.properties ? _vm.info.properties.title : ''
          }
        ]),expression:"[\n          'title',\n          {\n            initialValue: info.properties ? info.properties.title : ''\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":"页面关键词"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'keywords',
          {
            initialValue: _vm.info.properties ? _vm.info.properties.keywords : ''
          }
        ]),expression:"[\n          'keywords',\n          {\n            initialValue: info.properties ? info.properties.keywords : ''\n          }\n        ]"}],attrs:{"type":"textarea","placeholder":"请填写页面中主要关键词，以英文逗号分割，建议数量控制在3～6个以内"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":"页面描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            initialValue: _vm.info.properties ? _vm.info.properties.description : ''
          }
        ]),expression:"[\n          'description',\n          {\n            initialValue: info.properties ? info.properties.description : ''\n          }\n        ]"}],attrs:{"type":"textarea","placeholder":"请填写对网页内容的简要介绍"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }