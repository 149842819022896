<template>
  <div class="m-visual-side-page">
    <div class="m-menu-wrap">
      <a-menu
        :openKeys.sync="showMenu"
        :inlineIndent="18"
        v-model="showMenu"
        style="width: 170px; border-right: none;"
        mode="inline"
      >
        <!-- 一级菜单 -->
        <a-sub-menu :key="navList.pageUrl" :class="activePathName === navList.pageUrl ? 'activeMenu' : ''">
          <!-- 二级菜单 -->
          <span slot="title">
            <a
              @click="outConfirm(navList)"
            >
              {{ navList.pageTitle }}
            </a>
          </span>
          <a-sub-menu v-for="secondItem in navList.child" :key="secondItem.pageUrl" :class="activePathName === secondItem.pageUrl ? 'activeMenu' : ''">
            <span slot="title">
              <a
                @click="outConfirm(secondItem)"
              >
                {{ secondItem.pageTitle }}
              </a>
            </span>
            <!-- 三级菜单 -->
            <a-sub-menu v-for="threeItem in secondItem.child" :key="threeItem.pageUrl" :class="activePathName === threeItem.pageUrl ? 'activeMenu' : ''">
              <span slot="title">
                <a
                  @click="outConfirm(threeItem)"
                >
                  {{ threeItem.pageTitle }}
                </a>
              </span>
              <!-- 四级菜单 -->
              <a-menu-item v-for="fourItem in threeItem.child" :key="fourItem.pageUrl" :class="activePathName === fourItem.pageUrl ? 'activeMenu' : ''">
                <a
                  @click="outConfirm(fourItem)"
                >
                  {{ fourItem.pageTitle }}
                </a>
              </a-menu-item>
            </a-sub-menu>
          </a-sub-menu>
        </a-sub-menu>
        <!-- <a-menu-item :key="screenSaver.pageUrl">
          <a
            :class="activePathName === screenSaver.pageUrl ? 'activeMenu' : ''"
            @click="outConfirm(`${screenSaver.pageUrl}?${screenSaver.id ? ('programId=' + screenSaver.id + '&') : ''}${screenSaver.parent ? ('parent=' + screenSaver.parent + '&') : ''}${screenSaver.template ? ('template=' + screenSaver.template + '&') : ''}preview=true`)"
          >
            {{ screenSaver.pageTitle }}
          </a>
        </a-menu-item> -->
      </a-menu>
    </div>
  </div>
</template>

<script>
import { removeLeaveConfirm } from '@/assets/js/util'
// import CMSLink from '~/components/common/Link'
export default {
  components: {
    // CMSLink
  },
  data() {
    return {
      navList: {},
      showMenu: [],
      activePathName: '',
      screenSaver: {}
    }
  },
  watch: {
    '$store.state.changeLeftNav': {
      handler: function (val = []) {
        if (val) {
          this.getLefNav()
        }
      }
    }
  },
  created() {
    this.getLefNav()
    this.getLeftNavList()
  },
  mounted() {
    this.activePathName = location.pathname
  },
  methods: {
    outConfirm(item) {
      window.event.stopPropagation()
      removeLeaveConfirm()
      const linkUrl = `${item.pageUrl}?${item.id ? ('programId=' + item.id + '&') : ''}${item.parent ? ('parent=' + item.parent + '&') : ''}${item.template ? ('template=' + item.template + '&') : ''}preview=true`
      location.href = linkUrl
    },
    titleClick(e) {
      console.log('titleClick', e)
    },
    getLefNav() {
      const programId = this.$store.state.programId
      this.$axios(`/bigscreen/program/left/nav/${programId}`).then((res) => {
        console.log(res.data)
        this.navList = res.data.child[0]
        // this.screenSaver = res.data.child[1]
      })
    },
    getLeftNavList() {
      const programId = this.$store.state.programId
      this.$axios(`/bigscreen/program/left/nav/parent/${programId}?url=${location.pathname}`).then((res) => {
        console.log(res.data.content)
        this.showMenu = res.data.content
      })
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '~/assets/css/4mixin.css';

.m-visual-side-page {
  width: 170px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: #fff;
  overflow-y: auto;
  box-shadow: 0 0 28px 0 rgb(24 144 255 / 10%);
}

.m-menu-wrap {
  .ant-menu-submenu-open,
  & .ant-menu-submenu-selected {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-menu-item-selected {
    background-color: #f5f8ff;
  }

  .activeMenu {
    background-color: #f5f8ff;
    color: #3360ff;

    .a {
      color: #3360ff;
    }
  }

  .ant-menu-item::after {
    left: 0;
    right: auto;
    border-color: #3360ff;
  }

  a {
    color: unset;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f5f8ff;
}
</style>

<style lang="less">
.m-visual-side-page {
  .m-btn-add {
    .ant-btn {
      width: 100%;
    }
  }

  .ant-menu-submenu-open {
    color: #1890ff;
  }
}

.ant-popover {
  z-index: 1002;
}
</style>
