import Vue from 'vue'
import VideoPlayer from 'vue-video-player/dist/ssr'
// import VideoHls from 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

const hls = require('videojs-contrib-hls')

Vue.use(hls)
Vue.use(VideoPlayer)
