<template>
  <div class="m-visual-side-page">
    <div class="m-btn-add">
      <a-button @click="handleAdd" type="primary">
        新增页面
      </a-button>
    </div>
    <div class="m-page-list">
      <a
        v-for="page in pageList"
        :key="page.key"
        @click="outConfirm(`${page.url}?${siteId ? ('siteId=' + siteId + '&') : ''}preview=true`)"
      >
        <div :class="['m-page', 'f-clearfix', page.key === $store.state.pageKey ? '' : 'z-selected']">
          <div class="g-l u-page-name">
            <a-icon type="file-text" /><span>{{ page.desc }}</span>
          </div>
          <div class="g-r-t">
            <div @click.stop="handleSetting(page, $event)" class="g-r-c">
              <a-icon type="setting" />
            </div>
            <a-divider type="vertical" />
            <a-popconfirm
              @confirm="handleDelete(page.key, 'page', $event)"
              title="你确定删除该页面吗？"
              ok-text="确定"
              cancel-text="取消"
            >
              <div @click.stop class="g-r-c">
                <a-icon type="delete" />
              </div>
            </a-popconfirm>
          </div>
        </div>
      </a>
      <template v-if="formList.length > 0">
        <a-divider />
        <div class="u-type-title">
          表单
        </div>
        <a
          v-for="form in formList"
          :key="form.key"
          @click="outConfirm(`${form.url}?${siteId ? ('siteId=' + siteId + '&') : ''}preview=true`)"
        >
          <div class="m-page f-clearfix">
            <div class="g-l u-page-name">
              <a-icon type="file-text" /><span>{{ form.desc || form.body.title }}</span>
            </div>
            <div class="g-r-t">
              <div @click.stop="handleSetting(form, $event)" class="g-r-c">
                <a-icon type="setting" />
              </div>
              <a-divider type="vertical" />
              <a-popconfirm
                @confirm="handleDelete(form.key, 'form', $event)"
                title="你确定删除该表单吗？"
                ok-text="确定"
                cancel-text="取消"
              >
                <div @click.stop class="g-r-c">
                  <a-icon type="delete" />
                </div>
              </a-popconfirm>
            </div>
          </div>
        </a>
      </template>
    </div>
    <PageAddModal
      ref="pageAddForm"
      :visible="visible"
      :info="info"
      :modalLoading="modalLoading"
      @cancel="handleCancel"
      @create="handleCreate"
    />
  </div>
</template>

<script>
import { addLeaveConfirm, removeLeaveConfirm } from '@/assets/js/util'
import PageAddModal from './pageAddModal'

export default {
  components: {
    PageAddModal
  },
  data() {
    return {
      pageList: [],
      formList: [],
      visible: false,
      info: {},
      modalLoading: false
    }
  },
  computed: {
    siteId() {
      return this.$store.state.siteId || this.$store.state.siteInfo.id
    }
  },
  mounted() {
    this.getSiteDetail()
    this.getPageList()
    this.getFormPageList()
    this.form = this.$refs.pageAddForm.form
  },
  methods: {
    outConfirm(url) {
      if (location.pathname === url.substring(0, url.indexOf('?'))) {
        return
      }
      removeLeaveConfirm()

      this.$confirm({
        title: '是否确定跳转',
        content: '跳转后未保存的数据将会丢失',
        onOk() {
          location.href = url
        },
        onCancel() {
          addLeaveConfirm()
        }
      })
    },
    handleDelete(key, type, e) {
      e.preventDefault()
      if (type === 'page') {
        this.pageDelete(key).then(() => {
          const index = this.pageList.findIndex(page => page.key === key)

          if (index !== -1) {
            this.pageList.splice(index, 1)
          }
        })
      } else if (type === 'form') {
        this.pageDelete(key).then(() => {
          const index = this.formList.findIndex(page => page.key === key)

          if (index !== -1) {
            this.formList.splice(index, 1)
          }
        })
      }
    },
    handleAdd() {
      if (this.pageList.length + this.formList.length >= 100) {
        this.$message.error('最多100个页面')
        return
      }

      this.info = {}
      this.visible = true
    },
    handleCreate() {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }

        if (values.url.indexOf('/') !== 0) {
          // 默认加上/
          values.url = '/' + values.url
        }

        if (this.info.key) {
          this.pageUpdate({ ...this.info, ...values })
        } else {
          this.pageCreate(values)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },
    handleSetting(page, e) {
      e.preventDefault()
      this.info = page
      this.visible = true
    },
    getPageList() {
      this.$axios.get('/cms/page/getCmsPage').then((res) => {
        this.pageList = res.data.items
      }).catch((e) => {
        console.error(e)
      })
    },
    getFormPageList() {
      this.$axios.get('/cms/page/getCmsPage?type=FORM&isPublish=true').then((res) => {
        this.formList = res.data.items
      }).catch((e) => {
        console.error(e)
      })
    },
    getSiteDetail() {
      this.$axios.get('/cms/site' + (this.siteId ? `/${this.siteId}` : '/name')).then((res) => {
        this.$store.commit('updateSiteInfo', res.data)
      }).catch((e) => {
        console.error(e)
      })
    },
    pageCreate(values) {
      this.modalLoading = true
      const data = {
        ...values,
        properties: {
          title: values.title,
          keywords: values.keywords,
          description: values.description
        }
      }

      this.$axios.post('/cms/page/createCmsPage' + (this.siteId ? `/${this.siteId}` : ''), data).then((res) => {
        this.modalLoading = false
        this.getPageList()
        this.visible = false
        this.form.resetFields()
      }).catch(() => {
        this.modalLoading = false
      })
    },
    pageUpdate(values) {
      this.modalLoading = true
      const data = {
        ...values,
        properties: {
          title: values.title,
          keywords: values.keywords,
          description: values.description
        }
      }

      return this.$axios.post('/cms/page/saveOrUpdateCmsPageProperties' + (this.siteId ? `/${this.siteId}` : ''), data).then((res) => {
        this.modalLoading = false
        this.getPageList()
        this.getFormPageList()
        this.visible = false
        this.form.resetFields()
      }).catch(() => {
        this.modalLoading = false
      })
    },
    pageDelete(key) {
      return this.$axios.delete(`/cms/page/page/delete/${key}`)
    },
    formDelete(key) {
      return this.$axios.delete(`/cms/form/page/delete/${key}`)
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '~/assets/css/4mixin.css';

.m-visual-side-page {
  position: absolute;
  left: 70px;
  top: 61px;
  bottom: 0;
  background-color: #f8fafc;
  border-right: 1px solid #dbdfe9;
  width: 340px;
  padding: 0 14px 14px;
  overflow-y: auto;

  .m-btn-add {
    padding: 14px 0;
    border-bottom: 1px solid #e4e8f0;
  }

  .m-page {
    border: 1px solid #dbdfe9;
    border-radius: 4px;
    background-color: rgba(74, 127, 243, 0.06);
    line-height: 40px;
    padding: 0 15px;
    margin-top: 15px;
    display: flex;

    &.z-selected {
      background-color: #fff;
    }
  }

  .u-page-name {
    /* width: 220px; */
    flex: 1;
    @mixin ellipsis;

    span {
      margin: 0 5px;
    }
  }

  .u-type-title {
    color: #333;
  }
}
</style>

<style lang="less">
.m-visual-side-page {
  .m-btn-add {
    .ant-btn {
      width: 100%;
    }
  }
}

.ant-popover {
  z-index: 1002;
}

.g-r-t {
  // float: right;
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -5px;
}
.g-r-c {
  width: 22px;
  text-align: center;
}
</style>
