import _ from 'lodash'

export function fixConfig({ properties, category }, data) {
  if (data.fixed) {
    return data.reqData
  }

  const componentId = data.id
  Object.keys(properties).forEach((key) => {
    let configItem = properties[key]

    const fixItem = fix(properties, data.reqData, [componentId], key)

    if (configItem.type === 'FieldsGroup' && configItem.mixin) {
      delete properties[key]
      properties = { ...properties, ...fixItem }
    } else {
      configItem = fixItem
    }
  })

  return {
    category: category || '',
    ...properties
  }
}

export function fixByConfig(config, data) {
  const res = {}

  Object.keys(config).forEach((key) => {
    const temp = { ...config[key] }
    temp.value = data.key
    res[key] = temp
  })

  return res
}

export function fixId(reqData) {
  return reqData
}

// export function fixAddListItem(parentVisualKeys) {
//   config.value.push(fixListChildren(listConfig, elementData, parentVisualKeys, index))
// }

/**
 * 拼装列表
 * @param {Object} config 列表的config配置
 * @param {Array} data 需要装载的列表数据
 * @param {Array} listVisualKeys 列表的层级key
 */
function fixList(config, data, listVisualKeys) {
  config.value = []

  data.forEach((element, index) => {
    config.value.push(fixListChildren(config, element, listVisualKeys, index))
  })

  // list 新增需要的数据 developValue 也需要提前拼装，预留index替换的口子
  if (config.visualValue && !config.fixedVisualValue) {
    config.visualValue = fixListChildren(config, config.visualValue, listVisualKeys, '{index}')
    config.fixedVisualValue = 1
  }
}

function fixListChildren(config, element, parentVisualKeys, index) {
  let item = {}
  if (element.clipData) {
    item.clipData = {}
    item.clipData.value = element.clipData
  }

  Object.keys(_.cloneDeep(config.children)).forEach((key) => {
    const configItem = config.children[key]

    const fixItem = fix(config.children, element, [...parentVisualKeys, index], key)

    if (configItem.type === 'FieldsGroup' && configItem.mixin) {
      delete item[key]
      item = { ...item, ...fixItem }
    } else {
      item[key] = fixItem
    }
  })

  return item
}

function fixGroup(config, data, parentVisualKeys, key) {
  // TODO 不应该变config 变config list的value
  const configItem = config[key]

  if (configItem.mixin) {
    // mixin模式父级结构会被移除
    const tempObj = {}
    Object.keys(configItem.children).forEach((childKey) => {
      const item = { ...configItem.children[childKey] }
      item.title = `${configItem.title}-${item.title}`
      item.visualKeys = [...parentVisualKeys, childKey]
      item.value = data[childKey] || item.defaultValue || ''

      if (!item.value && item.type === 'Image') {
        if (childKey.indexOf('3x_h5') !== -1) {
          item.value = data[Object.keys(configItem.children).find(child => child.indexOf('2x') !== -1 && child.indexOf('h5') === -1)]
        } else if (childKey.indexOf('2x_h5') !== -1) {
          item.value = data[Object.keys(configItem.children).find(child => child.indexOf('2x') === -1 && child.indexOf('3x') === -1)]
        }
      }

      tempObj[childKey] = item
    })

    return tempObj
  } else {
    Object.keys(configItem.children).forEach((childKey) => {
      const item = configItem.children[childKey]
      item.visualKeys = [...parentVisualKeys, key, childKey]
      item.value = data[key][childKey]
    })

    return configItem
  }
}

export function fix(config, data, parentVisualKeys, key) {
  if (!data) {
    console.log(parentVisualKeys)
    return ''
  }

  const configItem = config[key]
  const dataItem = data[key]
  const visualKeys = [...parentVisualKeys, key]
  configItem.visualKeys = visualKeys

  let res = {}

  switch (configItem.type) {
    case 'Text':
    case 'MultiText':
    case 'Radio':
    case 'VideoRadio':
    case 'Image':
    case 'Poster':
    case 'Audio':
    case 'Video':
    case 'BgText':
    case 'Link':
      configItem.value = dataItem || configItem.defaultValue || ''
      res = configItem
      break

    case 'VideoImageList':
    case 'VideoImageGroup':
    case 'MutiBgImage':
    case 'List':
      fixList(configItem, dataItem, configItem.visualKeys)
      res = configItem
      break

    case 'FieldsGroup':
      res = fixGroup(config, data, parentVisualKeys, key)
      break

    default:
      break
  }

  return { ...res }
}

/**
 * 获取本地开发数据，用于新增展示
 * @param {*} properties
 */
export function getDevelopValue(properties) {
  const res = {}

  Object.keys(properties).forEach((key) => {
    const item = properties[key]

    switch (item.type) {
      case 'List':
      case 'VideoImageList':
      case 'VideoImageGroup':
      case 'Text':
      case 'Link':
      case 'MultiText':
      case 'Image':
      case 'Poster':
      case 'Audio':
      case 'Video':
      case 'MutiBgImage':
      case 'BgText':
      case 'VideoRadio':
      case 'Radio':
        res[key] = item.developValue || item.defaultValue || []
        break

      case 'FieldsGroup':
        if (item.mixin) {
          Object.assign(res, getDevelopValue(item.children) || {})
        } else {
          res[key] = getDevelopValue(item.children) || {}
        }
        break

      default:
        break
    }
  })

  return res
}

/**
 * 解析为后端需要存储的数据
 * @param {Array} values
 */
export function parseFix(values) {
  const components = _.cloneDeep(values)

  const req = {
    body: [],
    components: {}
  }

  components.forEach((component) => {
    req.body.push(component.id)

    const item = {
      type: component.key,
      key: component.id,
      properties: {}
    }

    if (component.fixed) {
      Object.keys(component.reqData).forEach((key) => {
        const value = component.reqData[key].value

        item.properties[key] = parseValue(value)
      })
    } else {
      Object.keys(component.reqData).forEach((key) => {
        item.properties[key] = component.reqData[key]
      })
    }

    req.components[component.id] = item
  })

  return req
}

function parseValue(value) {
  if (Array.isArray(value)) {
    value.forEach((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = parseValue(item[key].value)
      })
    })
  }

  return value
}
