export function dealViewScale() {
  const docDom = document.querySelector('.gf-module')
  // const url = window.location.href
  // if (url.indexOf('preview=true') !== -1) {
  const moduleScale = (document.body.offsetWidth - 170 - 300) / 1920
  docDom.style.transform = 'scale(' + moduleScale + ')'
  // }
}

export const formatDate = (val = 0) => {
  if (val === null || val === undefined) {
    return ''
  }
  let h = ''; let m = ''; let s = ''
  const date = val % 3600
  m = parseInt(date / 60)
  h = parseInt(val / 3600)
  s = date % 60
  if (h < 10) {
    h = '0' + h
  }
  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }
  return h + ':' + m + ':' + s
}

export function dealLocationSearch() {
  const urlSearch = location.search
  const substrUrlSearch = urlSearch.substr(1)
  const arr = substrUrlSearch.split('&')
  const obj = {}
  let newarr = []
  arr.forEach((value) => {
    newarr = value.split('=')
    if (typeof obj[newarr[0]] === 'undefined') {
      obj[newarr[0]] = newarr[1]
    }
  })
  return obj
}

export function getUrlParams(url) {
  const substrUrlSearch = url.substr(url.indexOf('?') + 1)
  const arr = substrUrlSearch.split('&')
  const obj = {}
  let newarr = []
  arr.forEach((value) => {
    newarr = value.split('=')
    if (typeof obj[newarr[0]] === 'undefined') {
      obj[newarr[0]] = newarr[1]
    }
  })
  return obj
}

// 是否是链接
export const isUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
  return regexp.test(url)
}

// 是否是SVG
export const isNotSvg = (url) => {
  return url.indexOf('.svg') === -1
}
// 是否以某后缀结尾的
export const isNotSuffix = (url, suffix) => {
  return url.indexOf(`.${suffix}`) === -1
  // 返回false表示是以suffix后缀结尾的
}

// 替换jpg,png图片后缀为webp
export const dealImageSuffix = (url) => {
  let returnUrl = url
  if (url.indexOf('nos-creative-cms.nos-jd.163yun.com') !== -1) {
    if (url.indexOf('imageView') > -1) {
      returnUrl = `${url}&type=webp&quality=100`
    } else {
      // returnUrl = `${returnUrl.replace(/(\?|#)[^'"]*/, '')}?imageView&type=webp&quality=100`
      // eslint-disable-next-line no-lonely-if
      if (returnUrl.indexOf('?') !== -1 && returnUrl.indexOf('&') !== -1) {
        returnUrl = `${returnUrl}&imageView&type=webp&quality=100`
      } else {
        returnUrl = `${returnUrl}?imageView&type=webp&quality=100`
      }
    }
    return returnUrl
  }
  return returnUrl

  // const fileExtension = url.substring(url.lastIndexOf('.') + 1)
  // if (fileExtension === 'png') {
  //   return url.replace('.png', '.webp')
  // } else if (fileExtension === 'jpg') {
  //   return url.replace('.jpg', '.webp')
  // } else {
  //   return url
  // }
}

const nosUrl = 'https://nos-creative-cms.nos-jd.163yun.com/'
export const realImageUrl = (imgUrl, isSupportWebp) => {
  // 本地图片且非svg 将路径转换为nos图片地址
  if (imgUrl && !isUrl(imgUrl) && isNotSvg(imgUrl)) {
    if (imgUrl.charAt(0) !== '/') {
      imgUrl = `/${imgUrl}`
    }
    const key = `static${imgUrl.split('/').join('_')}`
    let url = `${nosUrl}${key}`
    if (isSupportWebp) {
      url = `${url}?imageView&type=webp&quality=100`
    }
    return url
  }
  if (isUrl(imgUrl) && isSupportWebp && isNotSuffix(imgUrl, 'gif')) {
    return dealImageSuffix(imgUrl)
  }
  return imgUrl
}
