<template>
  <a-modal
    :visible="visible"
    :confirm-loading="modalLoading"
    @cancel="() => { $emit('cancel') }"
    @ok="() => { $emit('create') }"
    title="页面设置"
    ok-text="确定"
    cancel-text="取消"
  >
    <a-form :form="form" layout="vertical">
      <a-form-item label="页面名称">
        <a-input
          v-decorator="[
            'desc',
            {
              initialValue: info.desc || '',
              rules: [{ required: true, message: '请输入页面名称' }]
            }
          ]"
          placeholder="XXX页"
        />
      </a-form-item>
      <a-form-item label="URL">
        <a-input
          v-decorator="[
            'url',
            {
              initialValue: info.url || '',
              rules: [
                { required: true, message: '请输入URL' },
                { pattern: /^[0-9a-z_\/]{1,}$/, message: '请输入英文、数字或_' }
              ]
            }
          ]"
          :addon-before="`https://${siteInfo.subdomain}${siteInfo.domainName}`"
        />
        <span slot="extra">可将上述链接复制到您指定的页面按钮链接中<a @click="copy">复制链接</a></span>
      </a-form-item>
      <a-form-item label="浏览器标题title">
        <a-input
          v-decorator="[
            'title',
            {
              initialValue: info.properties ? info.properties.title : ''
            }
          ]"
          placeholder="请输入"
        />
      </a-form-item>
      <a-form-item label="页面关键词">
        <a-input
          v-decorator="[
            'keywords',
            {
              initialValue: info.properties ? info.properties.keywords : ''
            }
          ]"
          type="textarea"
          placeholder="请填写页面中主要关键词，以英文逗号分割，建议数量控制在3～6个以内"
        />
      </a-form-item>
      <a-form-item label="页面描述">
        <a-input
          v-decorator="[
            'description',
            {
              initialValue: info.properties ? info.properties.description : ''
            }
          ]"
          type="textarea"
          placeholder="请填写对网页内容的简要介绍"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { copyTextToClipboard } from '~/assets/js/util'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modalLoading: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    siteInfo() {
      return this.$store.state.siteInfo
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'page_add_modal' })
  },
  methods: {
    copy() {
      copyTextToClipboard(`https://${this.siteInfo.subdomain}${this.siteInfo.domainName}${this.info.url}`, () => {
        this.$message.success('复制成功')
      }, () => {
        this.$message.error('复制失败，浏览器不支持')
      })
    }
  }
}
</script>
