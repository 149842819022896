<template>
  <div class="m-visual-side-layout">
    <div class="u-tip f-clearfix">
      <div class="g-l" style="width: 9%;">
        <a-icon type="info-circle" style="color: #faad14;" />
      </div>
      <div class="g-r m-visual-p-list" style="width: 91%;">
        <p>按住内容区组件进行上下拖拽调整顺序。</p>
        <p>点击组件可跳转对应位置。</p>
        <p>头部及页尾组件无法调整顺序或删除。</p>
      </div>
    </div>
    <div class="m-component-list m-component-list-bottom">
      <div class="m-component z-disabled">
        导航
      </div>
      <draggable
        v-model="list"
        @end="onEnd"
        group="people"
      >
        <div v-for="element in list" :key="element.id" @click="goAnchor(element.id)" class="m-component f-clearfix">
          <div class="g-l">
            {{ element.desc }}
          </div>
          <div v-if="element.reqData && element.reqData.type !== 'FORM'" class="g-r g-r-c">
            <a-popconfirm
              @confirm="handleDelete(element.id)"
              title="你确定删除该组件吗？"
              ok-text="确定"
              cancel-text="取消"
            >
              <div @click.stop style="width: 100%; height: 100%; cursor: pointer;">
                <a-icon type="delete" />
              </div>
            </a-popconfirm>
          </div>
        </div>
      </draggable>
      <div class="m-component z-disabled">
        页尾
      </div>
    </div>
  </div>
</template>

<script>
import { sendMessage2Iframe } from '~/assets/js/util'

export default {
  data() {
    return {
      list: this.getList()
    }
  },
  watch: {
    '$store.state.components': {
        deep: true,
        handler: function (val = []) {
        this.list = this.getList()
        // this.$store.commit('updateNavList', false)
        // this.$store.commit('updateNavList', true)
      }
    }
  },
  methods: {
    handleDelete(id) {
      const index = this.$store.state.components.findIndex(component => component.id === id)
      this.$store.commit('deleteComponentById', { index })

      sendMessage2Iframe({
        index,
        type: 'deleteComponentById'
      })
      this.$store.commit('updateNavList', false)
      this.$store.commit('updateNavList', true)
    },
    onEnd(target) {
      // 组件顺序未改变不更新数据
      let componentsKey = ''
      this.list.forEach((value) => {
        componentsKey += value.id
      })
      if (componentsKey === this.$store.state.componentsKey) {
        return
      }

      this.$store.commit('changeComponentIndex', this.list)
      const list = this.list
      sendMessage2Iframe({
        list,
        type: 'changeComponentIndex'
      })
      this.$store.commit('updateNavList', false)
      this.$store.commit('updateNavList', true)
      this.$store.commit('updateComponentsKey', componentsKey)
    },
    getList() {
      const list = []
      const configs = this.$store.state.configs

      this.$store.state.components.forEach((component) => {
        const configKey = configs.keys().find(key => key === `./${component.key}/config.json`)

        if (configKey) {
          const configItem = configs(configKey)[component.key]
          if (component.reqData.title && component.reqData.title.value) {
            component.desc = component.reqData.title.value
          } else if (component.reqData.mainTlt && component.reqData.mainTlt.value) {
            component.desc = component.reqData.mainTlt.value
          } else if (component.reqData.secondTlt && component.reqData.secondTlt.value) {
            component.desc = component.reqData.secondTlt.value
          } else {
            component.desc = configItem.desc
          }
          component.category = configItem.category
        }
        if (['Header', 'Footer'].indexOf(component.category) !== -1) {
          return
        }

        list.push(component)
      })

      this.list = list
      this.getComponentsKey(this.list)
      return list
    },
    getComponentsKey(list) {
      let componentsKey = ''
      list.forEach((value) => {
        componentsKey += value.id
      })
      this.$store.commit('updateComponentsKey', componentsKey)
    },
    goAnchor(val) {
      const pageId = document.getElementById(val)
      if (pageId) {
        window.scrollTo({
          top: pageId.offsetTop - 66
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.m-visual-side-layout {
  position: absolute;
  left: 70px;
  top: 61px;
  bottom: 0;
  background-color: #f8fafc;
  border-right: 1px solid #dbdfe9;
  width: 340px;
  padding: 0 14px;
  overflow-y: auto;

  .u-tip {
    color: #26385b;
    line-height: 21px;
    background: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 4px;
    padding: 12px 15px;
    margin-top: 15px;
  }

  .m-component {
    border: 1px solid #dbdfe9;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 15px;
    color: #000;
    background-color: #fff;
    border-left: 2px solid #54db5b;
    margin-top: 12px;

    .g-l {
      max-width: 239px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      cursor: default;
    }

    &.z-disabled {
      border-left: 1px solid #dbdfe9;
    }
  }
}

.m-visual-p-list {
  p {
    margin-bottom: 0px;
  }
}

.m-component-list-bottom {
  padding-bottom: 14px;
}
</style>
