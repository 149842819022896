import Vue from 'vue'

export default function ({ app, store, route, req, redirect }) {
  const errorHandler = (error, vm, info) => {
    store.dispatch('getErr', {
      err: error.stack,
      hook: info,
      url: vm.$route.fullpath,
      req
    })
  }
  Vue.config.errorHandler = errorHandler

  // Vue 实例
  Vue.prototype.$throw = (error, vm, info) => errorHandler(error, vm, info)
  // context - 目前只有 asyncData
  app.$serverThrow = ({ error, route }) => {
    // 服务器端发送的请求，需要手动带上请求头等信息。这里给传过去
    store.dispatch('getErr', {
      err: error.stack,
      hook: 'asyncData',
      url: route.fullPath,
      req
    })
  }
}
