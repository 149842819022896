import moment from 'moment'

export function getQuery() {
  let formattedParams = {}
  const list = location.search.split('?')

  if (list.length > 1) {
    formattedParams = query2object(list[1])
  }

  return formattedParams
}

export function query2object(query) {
  const formattedParams = {}

  const params = query.split('&')

  for (let i = 0; i < params.length; i++) {
    formattedParams[params[i].split('=')[0]] = params[i].split('=')[1]
  }

  return formattedParams
}

export function object2query(object) {
  let str = ''

  Object.keys(object).forEach((key) => {
    str = str + '&' + key + '=' + object[key]
  })

  return str.replace('&', '')
}

export function copyTextToClipboard(text, successCallback, failCallback) {
  const textArea = document.createElement('textarea')
  textArea.style.width = '0'
  textArea.style.height = '0'
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.select()
  try {
    if (document.execCommand('copy')) {
      successCallback && successCallback()
    } else {
      failCallback && failCallback()
    }
  } catch (err) {
    failCallback && failCallback()
  }
  document.body.removeChild(textArea)
}

export function addLeaveConfirm() {
  window.onbeforeunload = function (e) {
    e = e || window.event

    // 兼容IE8和Firefox 4之前的版本
    if (e) {
      e.returnValue = '未保存的数据将会丢失'
    }

    // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    return '未保存的数据将会丢失'
  }
}

export function removeLeaveConfirm() {
  window.onbeforeunload = null
}

export function sendMessage2Iframe(data) {
  const iframe = window.document.getElementById('H5Iframe')
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(JSON.stringify(data), '*')
  }
}

export function log(msg) {
  console.info(moment(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'), msg)
}

export function debounce(fn, delay = 500) {
  const _this = this
  let timer = null
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    fn.apply(_this, arguments)
    clearTimeout(timer)
  }, delay)
}
