<template>
  <div class="m-visual-side">
    <div class="m-logo" />
    <div v-if="!isBigSrceen" class="m-menus">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="{ 'm-menu': true, 'z-selected': item.action === tab }"
        @click="handleClick(item.action)"
      >
        <a-icon :type="item.icon" class="u-side-icon" />
        <div>{{ item.title }}</div>
      </div>
    </div>
    <ComponentList v-show="tab === 'component'" />
    <template v-if="!isBigSrceen">
      <PageList v-show="tab === 'page'" />
    </template>
    <!-- v-if="needMenu" -->
    <GfPageList v-if="isBigSrceen && needMenu" />
    <Layout v-show="tab === 'layout'" />
    <Theme v-show="tab === 'theme'" />
  </div>
</template>

<script>
import ComponentList from './componentList'
import GfPageList from './GfPageList'
import PageList from './pageList'
import Layout from './layout'
import Theme from './theme'
import { showMenuProgram } from '@/constants/program'

export default {
  components: {
    ComponentList,
    GfPageList,
    PageList,
    Layout,
    Theme
  },
  data() {
    return {
      list: [{
        icon: 'bg-colors',
        title: '风格',
        action: 'theme'
      }, {
        icon: 'appstore',
        title: '组件',
        action: 'component'
      }, {
        icon: 'file-text',
        title: '页面',
        action: 'page'
      }, {
        icon: 'ordered-list',
        title: '布局',
        action: 'layout'
      }],
      tab: '',
      isBigSrceen: this.$store.state.isBigScreen
    }
  },
  computed: {
    needMenu() {
      const screenProgram = this.$store.state.screenProgram
      let needMenu = true
      if (screenProgram && showMenuProgram.indexOf(screenProgram.subtype) > -1) {
        needMenu = false
      }
      return needMenu
    }
  },
  methods: {
    handleClick(action) {
      if (this.tab === action) {
        this.tab = ''
      } else {
        this.tab = action
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.m-visual-side {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #1e74f4;
  color: #fff;
  z-index: 998;

  .m-logo {
    height: 61px;
  }

  .m-menus {
    width: 70px;
    text-align: center;
  }

  .m-menu {
    padding: 15px;
    cursor: pointer;

    &:hover,
    &.z-selected {
      background-color: #0050c7;
    }
  }

  .u-side-icon {
    font-size: 26px;
    margin-bottom: 5px;
  }
}
</style>
