<template>
  <div
    :class="{ 'z-large': tab.list }"
    class="m-visual-side-component"
  >
    <div class="m-component-tabs">
      <div
        v-for="item in list"
        :key="item.key"
        @click="handleClickTab(item.key)"
        :class="{ 'z-selected': tab.key === item.key }"
        class="m-component-tab"
      >
        {{ item.value }}
      </div>
    </div>
    <div
      ref="mComponentList"
      v-show="tab.list"
      class="m-component-list"
    >
      <template
        v-for="item in tab.list"
      >
        <div
          :key="item.type"
          :class="{ 'z-selected': tab.unique && $store.state.components.findIndex(component => component.key === item.type) !== -1 }"
          class="m-component"
        >
          <div class="m-cover">
            <img v-if="item.cover" :src="item.cover" class="m-cover-img">
            <p v-else>
              组件缩略图
            </p>
          </div>
          <div class="u-content f-clearfix">
            <div :title="item.desc" class="g-l content-name">
              {{ item.desc }}
            </div>
            <div class="g-r">
              <a :href="`/?components=${item.type}`" target="_blank">预览</a>
              <a-divider type="vertical" />
              <!-- TODO 当前模板只有一个头尾 -->
              <template v-if="tab.unique">
                <a class="f-selected">已选中</a>
                <a
                  @click="handleUniqueSelected(item)"
                  class="f-replace"
                >
                  替换
                </a>
              </template>
              <a v-else @click="handleAdd(item)">添加</a>
            </div>
          </div>
          <div v-if="tab.unique && $store.state.components.findIndex(component => component.key === item.type) !== -1" class="u-checkbox u-checkbox-ui">
            <a-icon type="check" />
            <!-- <a-checkbox :checked="$store.state.components.findIndex(component => component.key === item.type) !== -1" /> -->
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { sendMessage2Iframe } from '~/assets/js/util'
import { getDevelopValue } from '~/assets/js/visualFix'
import { categoryList } from '~/constants/category'

// 获取组件分类列表
/* 1-企业站
  2-主站（g.163yun）
  3-分站
  4-主站（di.163yun）
*/

function getCategory(configs, templateId) {
  const tempCategoryList = _.cloneDeep(categoryList)

  configs.keys().forEach((key) => {
    const item = configs(key)

    Object.keys(item).forEach((contentKey) => {
      const content = item[contentKey]

      if (content.category) {
        if (!content.templateId || content.templateId === templateId || (Array.isArray(content.templateId) && content.templateId.indexOf(templateId) !== -1)) {
          const index = tempCategoryList.findIndex(element => element.key.indexOf(content.category) !== -1)

          if (index !== -1) {
            tempCategoryList[index].list.push(content)
          }
        }
      }
    })
  })

  return tempCategoryList.filter(item => item.list.length > 0)
}

export default {
  data() {
    return {
      tab: {}
    }
  },
  computed: {
    list() {
      return getCategory(this.$store.state.configs, this.$store.state.siteInfo.templateId)
    }
  },
  methods: {
    handleClickTab(key) {
      if (this.tab.key === key) {
        this.tab = {}
      } else {
        this.$refs.mComponentList.scrollTop = 0
        this.tab = this.list.find(item => item.key === key) || {}
        this.tab.list.sort((a, b) => a.sortIndex - b.sortIndex)
      }
    },
    handleUniqueSelected(item) {
      // TODO 头尾组件做唯一性校验
      const index = this.$store.state.components.findIndex(component => component.category === item.category)
      if (index !== -1) {
        // const newItem = Object.values(this.$store.state.siteInfo.commonsComponents).find(value => value.type === item.type)
        const id = `COMPONENT${new Date().getTime()}`
        // console.log(newItem)
        this.$store.commit('replaceComponentByIndex', {
          index: index,
          item: {
            id: id,
            key: item.type,
            reqData: getDevelopValue(item.properties)
          }
        })
      } else {
        const id = `COMPONENT${new Date().getTime()}`
        const tempHeader = {
          index: index,
          item: {
            id: id,
            key: item.type,
            reqData: getDevelopValue(item.properties)
          }
        }
        if (item.category === 'Header') {
          this.$store.commit('updateComponentHeader', tempHeader)
        } else if (item.category === 'Footer') {
          this.$store.commit('updateComponentFooter', tempHeader)
        }
      }
      this.$store.commit('updateNavList', false)
      this.$store.commit('updateNavList', true)
    },
    handleAdd(component) {
      if (this.$store.state.components.length >= 20) {
        this.$message.error('最多添加20个组件')
        return
      }

      const id = `COMPONENT${new Date().getTime()}`
      const data = {
        id: id,
        key: component.type,
        reqData: getDevelopValue(component.properties)
      }
      const type = 'addComponent'

      this.$store.commit(type, data)

      setTimeout(() => {
        // TODO 此处需要保证页面元素渲染完成，目前设计只能先等待
        location.hash = `#${id}`
        this.$store.commit('updateNavList', false)
        this.$store.commit('updateNavList', true)
        // window.scrollTo(0, 99999)
      }, 300)

      // h5 iframe
      sendMessage2Iframe({
        ...data,
        type
      })
    }
  }
}
</script>

<style lang="postcss" scoped>
@import '~/assets/css/4mixin.css';

.m-visual-side-component {
  position: absolute;
  left: 70px;
  top: 61px;
  bottom: 0;
  background-color: #f8fafc;
  color: #2e384d;
  line-height: 60px;
  border-right: 1px solid #d8dfe9;

  &.z-large {
    width: 340px;
  }

  .m-component-tab {
    width: 100px;
    line-height: 60px;
    padding-left: 10px;
    cursor: pointer;

    &:hover,
    &.z-selected {
      background-color: rgba(74, 127, 243, 0.06);
      color: #1e74f4;
    }
  }

  .m-component-list {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 15px 15px;
    left: 100px;
    border-left: 1px solid #d8dfe9;
    overflow-y: auto;
  }

  .m-component {
    border: 1px solid #e4e8f0;
    border-radius: 3px;
    width: 210px;
    height: 115px;
    margin-top: 15px;
    position: relative;
    transition: border 0.2s;

    &:hover {
      border-color: #4a7ff3;
    }

    &:hover,
    &.z-selected {
      .ant-checkbox-input {
        display: block;
      }
    }

    &.z-selected {
      .f-selected {
        display: inline-block;
      }

      .f-replace {
        display: none;
      }
    }

    .f-selected {
      display: none;
    }

    .f-replace {
      display: inline-block;
    }

    .ant-checkbox-input {
      display: none;
    }
  }

  .m-cover {
    height: 85px;
    line-height: 85px;
    overflow: hidden;
    background-color: rgba(74, 127, 243, 0.06);
    font-size: 20px;
    color: #999;
    text-align: center;
  }

  .m-cover-img {
    max-height: 100%;
  }

  .u-content {
    padding: 0 10px;
    line-height: 30px;

    .content-name {
      width: 90px;
      @mixin ellipsis;
    }
  }

  .u-checkbox {
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: normal;
  }
  .u-checkbox-ui {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 29px;
    height: 20px;
    background-color: #3360ff;
    border-bottom-left-radius: 10px;
    text-align: center;
    z-index: 11;
    border-top-right-radius: 4px;
    .anticon-check {
      color: #fff;
    }
  }
}
</style>
