export default function ({ store, userAgent, req, $winstonLog }) {
  if (process.server) {
    try {
      userAgent = process.server ? req.headers['user-agent'] : navigator.userAgent
      const ua = userAgent.toLowerCase()
      let isSupportWebp = false
      if (req.headers.accept && req.headers.accept.split(',').indexOf('image/webp') > -1) {
        isSupportWebp = true
        store.commit('updateIsSupportWebp', isSupportWebp)
        return
      }
      const isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1
      const isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/i)
      if (isIOS) {
        const uaMatch = ua.match(/cpu iphone os (.*?) like mac os/)
        const iosVer = uaMatch && uaMatch.length && uaMatch.length >= 1 ? uaMatch[1] : null
        if (iosVer && iosVer.split('_')[0] >= 14) {
          isSupportWebp = true
        }
      }
      if (isAndroid) {
        isSupportWebp = true
      }
      store.commit('updateIsSupportWebp', isSupportWebp)
    } catch (error) {
      $winstonLog.error(`middleware supportWebp error: ${error}`)
    }
  }
}
