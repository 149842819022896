export const categoryList = [{
  key: 'Header-Footer',
  value: '页头页尾组件',
  unique: true,
  list: []
}, {
  key: 'System',
  value: '系统组件',
  list: []
}, {
  key: 'Other',
  value: '通用组件',
  list: []
}]
