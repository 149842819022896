<template>
  <div class="m-visual-header f-clearfix">
    <div class="header-back g-l">
      <!-- <a :href="cmsAdminUrl"><a-icon type="left" />{{ backText }}</a> -->
      <a :href="cmsAdminUrl" class="header-back__link">
        <span class="header-back_icon" />
      </a>{{ backText }}
    </div>
    <div class="header-opts g-r">
      <!-- <a :href="siteId ? `//preview-id-${siteId}.163cms.com` : `//preview-name-${siteName}.163cms.com`" target="_blank">
        <a-button>
          预览
        </a-button>
      </a> -->
      <!-- <a-button @click="handleSave">
        保存
      </a-button> -->
      <a-button
        @click="handlePublish"
        type="primary"
      >
        {{ isBigScreen ? '投放' : '发布' }}
      </a-button>
    </div>
    <div v-if="loading" class="loading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import { parseFix } from '~/assets/js/visualFix'
import { isUrl } from '~/utils/utils'

export default {
  data() {
    return {
      loading: false,
      programName: '',
      programId: ''
    }
  },
  computed: {
    isBigScreen() {
      return this.$store.state.isBigScreen
    },
    siteId() {
      return this.$store.state.siteId || this.$store.state.siteInfo.id
    },
    siteName() {
      return this.$store.state.siteName
    },
    cmsAdminUrl() {
      const domainData = this.$store.state.domainData
      let url = ''
      if (!domainData) {
        return url
      }
      if (this.isBigScreen) {
        if (window.location.href.indexOf('bigscreensaver_') === -1) {
          url = domainData.createDomain + '/screen/created'
        } else {
          url = domainData.createDomain + '/screen/setted'
        }
      } else {
        url = domainData.createDomain + '/home'
      }
      return url
    },
    backText() {
      let backText = process.env.backText
      if (this.isBigScreen) {
        backText = `${this.programName}`
      } else if (process.client && this.$store.state.siteInfo.templateId !== '1') {
        // 主、分站返回自己后台显示的文字
        backText = '返回门户控制台'
      } else {
        backText = '返回站点列表'
      }
      return backText
    },
    screenProgram() {
      return this.$store.state.screenProgram
    }
  },
  watch: {
    '$store.state.navList': {
      deep: true,
      handler: function (val = []) {
        if (val) {
          this.handleSave()
        }
      }
    }
  },
  mounted() {
    this.getProgram()
  },
  methods: {
    getReferrer() {
      const urlSearch = location.search
      const substrUrlSearch = urlSearch.substr(1) // 从起始索引号提取字符串中指定数目的字符
      const arr = substrUrlSearch.split('&'); const obj = {}; let newarr = []
      arr.forEach((value) => {
        newarr = value.split('=')
        if (typeof obj[newarr[0]] === 'undefined') {
          obj[newarr[0]] = newarr[1]
        }
      })
      return obj.referrer
    },
    getProgram() {
      const programId = this.$store.state.programId
      if (programId) {
        this.$axios.get('/bigscreen/program/' + this.$store.state.programId).then((res) => {
          this.programName = res.data.name
          this.id = res.data.id
        })
      }
    },
    handlePublish() {
      if (!this.linkCanPublish()) {
        return
      }
      // TODO 需要先保存当前页面才能发布
      if (this.isBigScreen) {
        const domainData = this.$store.state.domainData
        if (window.location.href.indexOf('bigscreensaver_') === -1) {
          location.href = domainData.createDomain + `/screen/launch/?id=${this.id}`
        } else {
          location.href = domainData.createDomain + `/screen/detail/?programId=${this.id}&typeName=屏保`
        }
      } else {
        this.$confirm({
          title: '确认发布当前站点吗？',
          content: '发布后可在「我的网站」标签页中查看发布的站点，且该站点将可访问。',
          onOk: this.publish
        })
      }
    },
    handleSave() {
      const programId = this.$store.state.programId
      const bigApiUrl = `/bigscreen/program/page/${programId}`
      const cmsApiUrl = '/cms/page/saveOrUpdateCmsPage' + (this.siteId ? `/${this.siteId}` : '')
      // const apiUrl = this.isBigScreen ? bigApiUrl : cmsApiUrl
      this.loading = true
      if (this.isBigScreen) {
        this.$store.commit('updateChangeLeftNav', null)
        this.$axios.put(bigApiUrl, { ...parseFix(this.$store.state.components), key: this.$store.state.pageKey }).then((res) => {
          this.$message.success('保存成功')
          this.$store.commit('updateChangeLeftNav', true)
          // this.pageList = res.data.items
          this.loading = false
        }).catch((e) => {
          console.error(e)
          this.loading = false
        })
      } else {
        this.$axios.post(cmsApiUrl, { ...parseFix(this.$store.state.components), key: this.$store.state.pageKey }).then((res) => {
          this.$message.success('保存成功')
          // this.pageList = res.data.items
          this.loading = false
        }).catch((e) => {
          console.error(e)
          this.loading = false
        })
      }
    },
    publish() {
      const apiUrl = '/cms/site/publish'
      this.loading = true
      this.$axios.put(apiUrl + (this.siteId ? `/${this.siteId}` : '')).then((res) => {
        this.$message.success('发布成功')
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 判断链接节目是否可以发布
    linkCanPublish() {
      let result = true
      if (this.screenProgram && this.screenProgram.subtype === 'LINK_PROGRAM') {
        const iframeValue = this.$store.state.components[0].reqData.iframe.value
        if (iframeValue && isUrl(iframeValue)) {
          result = true
        } else {
          this.$message.error('请输入有效链接')
          result = false
        }
      }
      return result
    }
  }
}
</script>

<style lang="less">
  .m-visual-header {
    .ant-btn {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
</style>

<style lang="less" scoped>
  .m-visual-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 61px;
    padding: 0 20px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 999;
    // border-bottom: 1px solid #d8dfe9;

    .header-back {
      height: 60px;
      line-height: 60px;
      font-size: 15px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      .anticon {
        margin-right: 30px;
      }
    }

    .header-opts {
      padding: 14px 0;
    }

    a {
      color: #000;
    }

    .loading {
      position: fixed;
      display: inline-block;
      opacity: 1;
      text-align: center;
      background: rgba(0, 0, 0, 0.05);
      width: 100%;
      height: 100vh;
      left: 0;
    }

    .ant-spin-spinning {
      position: absolute;
      top: 50%;
    }
  }

  .header-back__link {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    transition: all 0.25s;
    position: relative;
    margin-right: 20px;
    margin-left: -7px;

    &:hover {
      background: #F5F6F7;
    }
  }
  .header-back_icon {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: rotate(225deg);
  }
</style>
