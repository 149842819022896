<template>
  <div :class="[needMenu ? '' : 'm-visual-padding' , isBigScreen ? 'm-visual-bigScreen' : 'm-visual-container']">
    <client-only>
      <VisualHeader />
      <VisualSide />
    </client-only>
    <client-only v-if="iframe">
      <iframe id="H5Iframe" :src="iframeUrl" class="m-h5-iframe" />
    </client-only>
    <nuxt />
  </div>
</template>

<script>
import VisualSide from '~/components/common/visual/side'
import VisualHeader from '~/components/common/visual/header'
import { showMenuProgram } from '@/constants/program'

export default {
  components: {
    VisualSide,
    VisualHeader
  },
  data() {
    return {
      iframeUrl: '',
      isBigScreen: this.$store.state.isBigScreen
    }
  },
  computed: {
    iframe() {
      return this.$store.state.iframe
    },
    needMenu() {
      const screenProgram = this.$store.state.screenProgram
      let needMenu = true
      if (screenProgram && showMenuProgram.indexOf(screenProgram.subtype) > -1) {
        needMenu = false
      }
      return needMenu
    }
  },
  mounted() {
    this.iframeUrl = location.href.split('#')[0] + '&h5=true'
  }
}
</script>

<style lang="less" scoped>
.m-visual-bigScreen {
  position: relative;
  padding-top: 90px;
  padding-left: 200px;
  background: #eceff4;
}

.m-visual-container {
  position: relative;
  padding-top: 60px;
  padding-left: 70px;
}

.m-h5-iframe {
  display: block;
  width: 375px;
  margin: 20px auto;
  height: calc(100vh - 100px);
}

.m-visual-padding {
  padding-left: 30px;
}
</style>
