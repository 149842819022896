<template>
  <div class="m-theme">
    <a-radio-group v-model="value" @change="onChange">
      <p class="u-title">
        页面色系
      </p>
      <a-radio
        value="blue"
        class="radioBlue"
      >
        蓝色风格
      </a-radio>
      <a-radio
        value="red"
        class="radioRed"
      >
        红色风格
      </a-radio>
    </a-radio-group>
    <a-divider />
    <div :class="['m-test-c', theme.primaryColor ? theme.primaryColor : '']">
      <p class="effect">
        效果示意：
      </p>
      <a-row class="u-line">
        <a-button type="primary">
          按钮底色
        </a-button>
      </a-row>
      <a-row class="u-line-less">
        <a-button type="primary" ghost>
          按钮边框及文字颜色
        </a-button>
      </a-row>
      <a-row class="u-line">
        <a-breadcrumb-item>一级目录</a-breadcrumb-item>
        <a-breadcrumb-item><a>二级目录</a></a-breadcrumb-item>
      </a-row>
      <p class="u-break" />
      <a-radio-group v-model="iconValue" @change="onChangeIcon">
        <p class="u-component-title">
          组件标题样式
        </p>
        <a-radio value="onlyTitle">
          仅有标题
        </a-radio>
        <a-radio value="iconTitle">
          icon+标题
        </a-radio>
      </a-radio-group>
      <a-divider />
      <p class="effect">
        效果示意：
      </p>
      <p v-if="this.$store.state.theme.titleStyle == 'iconTitle'" class="m-title-before" />
      <p v-if="this.$store.state.theme.titleStyle == 'iconTitle'" class="m-title-before2" />
      <p class="mainTitle">
        组件主标题
      </p>
      <a-divider />
      h5预览
      <a-switch
        :checked="$store.state.iframe"
        @change="$store.commit('userIframe', !$store.state.iframe)"
        checked-children="开"
        un-checked-children="关"
      />
    </div>
  </div>
</template>

<script>
import { sendMessage2Iframe } from '@/assets/js/util'

export default {
  data() {
    return {
      value: this.$store.state.theme.primaryColor || 'blue',
      iconValue: this.$store.state.theme.titleStyle || 'onlyTitle'
    }
  },
  computed: {
    primaryColor() {
      return this.$store.state.theme.primaryColor
    },
    theme() {
      return this.$store.state.theme
    },
    siteId() {
      return this.$store.state.siteId || this.$store.state.siteInfo.id
    },
    titleStyle() {
      return this.$store.state.theme.titleStyle
    }
  },
  watch: {
    primaryColor(val) {
      this.value = val
    },
    titleStyle(val) {
      this.iconValue = val
    }
  },
  methods: {
    onChange(e) {
      const value = e.target.value
      this.$axios.put(`/cms/site/theme/${this.siteId}`, { ...this.theme, primaryColor: value }).then((res) => {
        this.$store.commit('updatePrimaryColor', value)
      }).catch((e) => {
        console.error(e)
      })

      sendMessage2Iframe({
        data: value,
        type: 'updatePrimaryColor'
      })
    },
    onChangeIcon(e) {
      const value = e.target.value
      this.$axios.put(`/cms/site/theme/${this.siteId}`, { ...this.theme, titleStyle: value }).then((res) => {
        this.$store.commit('updateTlt', value)
        console.log(res)
      }).catch((e) => {
        console.error(e)
      })

      sendMessage2Iframe({
        data: value,
        type: 'updateTlt'
      })
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-theme {
  position: absolute;
  left: 70px;
  top: 61px;
  bottom: 0;
  background-color: #f2fbfe;
  border-right: 1px solid #dbdfe9;
  width: 250px;
  padding: 20px 14px;
  overflow-y: auto;

  .u-title {
    text-align: left;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
    color: #000;
  }

  .u-component-title {
    text-align: left;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #000;
  }

  .ant-radio-wrapper {
    margin-right: 8px;
  }

  .radioBlue {
    color: $baseBlue;

    .ant-radio-inner {
      border-color: $baseBlue;
    }

    .ant-radio-inner::after {
      background-color: $baseBlue;
    }
  }

  .radioRed {
    color: $baseRed;

    .ant-radio-inner {
      border-color: $baseRed;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $baseRed;
    }

    .ant-radio-inner::after {
      background-color: $baseRed;
    }

    .ant-radio-checked {
      border-color: $baseRed;
    }

    .ant-radio-checked::after {
      border: 1px solid $baseRed;
    }
  }

  .ant-radio-group {
    display: block;
    text-align: center;
  }

  .m-test-c {
    color: #333;
    margin-bottom: 52px;
    position: relative;

    /* 本地和测试环境的样式有差异 在这里加个行高控制一下 */
    .effect {
      line-height: 18px;
      margin-bottom: 0;
    }

    .u-break {
      width: 245px;
      height: 9px;
      margin-left: -14px;
      background-color: #fff;
      margin-bottom: 0;
    }

    .u-line {
      margin: 15px 0;
      line-height: 18px;
    }

    .u-line-less {
      margin: 10px 0;
    }

    .mainTitle {
      margin: 6px 0 0 49px;
      font-size: 22px;
      line-height: 28px;
    }

    .m-title-before {
      position: absolute;
      width: 22px;
      height: 16px;
      left: 8px;
      top: 323px;
      background: linear-gradient(180deg, #1c67ff -108.71%, #245eff 100%);
      transform: matrix(-1, 0, -0.5, 1, 0, 0);
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .m-title-before2 {
      position: absolute;
      width: 22px;
      height: 16px;
      left: 16px;
      top: 319px;
      background: linear-gradient(180deg, #7ee2ef 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.5;
      transform: matrix(-1, 0, -0.5, 1, 0, 0);
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.red {
      .ant-btn-primary {
        background-color: $baseRed;
        border-color: $baseRed;
      }

      .ant-btn-background-ghost.ant-btn-primary {
        color: $baseRed;
        border-color: $baseRed;
      }

      .m-title-before {
        background: $baseRed;
      }

      .m-title-before2 {
        background: linear-gradient(180deg, #ef7e7e 0%, rgba(255, 255, 255, 0.24) 100%);
      }

      .u-line {
        a {
          color: $baseRed;
        }
      }
    }
  }
}
</style>
