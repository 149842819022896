import Vue from 'vue'

export default function ({ $axios, route }) {
  Vue.directive('track', {
    bind: (el, binding, vnode) => {
      if (binding.value) {
        if (binding.value.triggerType === 'click' && !route.query.preview && route.query.preview !== 'true') {
          el.addEventListener('click', () => {
            const params = {
              // siteId: binding.value.siteId,
              siteName: binding.value.siteName,
              statisticsType: binding.value.statisticsType
            }
            $axios.$post('statistics', params)
          }, false)
        }
      }
    }
  })
}
