import Vue from 'vue'
import {
  Layout,
  Modal,
  message,
  ConfigProvider,
  Pagination,
  Breadcrumb,
  Input,
  Icon,
  Carousel,
  Form,
  Select,
  Button,
  Upload,
  DatePicker,
  Cascader,
  Tree,
  Checkbox,
  Divider,
  Popconfirm,
  Dropdown,
  Menu,
  Radio,
  Row,
  Tabs,
  Switch,
  Col,
  Avatar,
  Spin,
  Slider,
  Popover,
  Tooltip,
  InputNumber,
  Rate,
  Progress
} from 'ant-design-vue'
import { iconfontUrl as scriptUrl } from '@/utils/constant'
const IconFont = Icon.createFromIconfontCN({
  scriptUrl
})
Vue.component(Layout.name, Layout)
Vue.component(Layout.Sider.name, Layout.Sider)
Vue.component(Layout.Header.name, Layout.Header)
Vue.component(Layout.Content.name, Layout.Content)
Vue.component('IconFont', IconFont)
Vue.use(Modal)
Vue.use(ConfigProvider)
Vue.use(Pagination)
Vue.use(Breadcrumb)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Carousel)
Vue.use(Form)
Vue.use(Select)
Vue.use(Button)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(Tree)
Vue.use(Checkbox)
Vue.use(Divider)
Vue.use(Popconfirm)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Radio)
Vue.use(Row)
Vue.use(Tabs)
Vue.use(Switch)
Vue.use(Col)
Vue.use(Avatar)
Vue.use(Spin)
Vue.use(Slider)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(InputNumber)
Vue.use(Rate)
Vue.use(Progress)

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
