import { isUrl } from '~/utils/utils'

export const state = () => ({
  siteId: '',
  siteName: '',
  siteInfo: {},
  pageKey: '',
  configs: null,
  components: [],
  cmsVisual: false,
  theme: {
    primaryColor: 'blue',
    titleStyle: 'onlyTitle'
  },
  userInfo: null,
  iframe: false,
  navList: false,
  changeLeftNav: false,
  programId: '',
  screenParentUrl: '',
  screenProgram: null,
  isBigScreen: false,
  isShowAudio: false,
  pageId: '',
  isShowScreenSaver: false,
  isTime: false,
  hasScreensaver: false,
  currentVisualKeys: null,
  isStopAudio: false,
  isReCallScreenSaver: true,
  domainData: null,
  componentsKey: '', // 判断组件是否真正顺序改变
  isDataChange: false,
  isSupportWebp: false
})

export const mutations = {
  updateIsDataChange(state, value) {
    state.isDataChange = value
  },
  updateIsReCallScreenSaver(state, value) {
    state.isReCallScreenSaver = value
  },
  updateIsStopAudio(state, value) {
    state.isStopAudio = value
  },
  updateCurrentVisualKeys(state, keys) {
    state.currentVisualKeys = keys
  },
  updateIsShowScreenSaver(state, value) {
    state.isShowScreenSaver = value
  },
  updateIsTime(state, value) {
    state.isTime = value
  },
  updateHasScreensaver(state, value) {
    state.hasScreensaver = value
  },
  updateIsShowAudio(state, value) {
    state.isShowAudio = value
  },
  updateNavList(state, value) {
    state.navList = value
  },
  updateChangeLeftNav(state, value) {
    state.changeLeftNav = value
  },
  updateIsBigScreen(state, value) {
    state.isBigScreen = value
  },
  updateScreenParentUrl(state, value) {
    state.screenParentUrl = value
  },
  updateScreenProgram(state, value) {
    state.screenProgram = value
  },
  updateProgramId(state, value) {
    state.programId = value
  },
  userIframe(state, value) {
    state.iframe = value
  },
  changeCmsVisual(state, value) {
    state.cmsVisual = value
  },
  updateSiteId(state, siteId) {
    state.siteId = siteId
  },
  updateSiteName(state, siteName) {
    state.siteName = siteName
  },
  updateSiteInfo(state, siteInfo) {
    state.siteInfo = siteInfo

    if (siteInfo.theme) {
      state.theme = siteInfo.theme
    }
  },
  updatePageKey(state, pageKey) {
    state.pageKey = pageKey
  },
  updateComponents(state, data) {
    state.components = data
  },
  updatePrimaryColor(state, data) {
    state.theme.primaryColor = data
  },
  updateTlt(state, data) {
    state.theme.titleStyle = data
  },
  updateTheme(state, data) {
    state.theme = data
  },
  /**
   * 更新指定组件的数据为fix
   * @param {*} state
   * @param {Object} payload {id:xxx, data: Object}
   */
  updateComponentById(state, payload) {
    // 针对移动端img_2x_h5的图片做初始化数据
    if (payload.data && payload.data.list && payload.data.list.value) {
      payload.data.list.value.forEach((value) => {
        if (value.img && value.img_2x_h5) {
          if (isUrl(value.img.value) && !isUrl(value.img_2x_h5.value)) {
            console.log('替换h5图片')
            value.img_2x_h5.value = value.img.value
            value.img_3x_h5.value = value.img_2x.value
          }
        }
        if (value.image && value.image_2x_h5) {
          if (isUrl(value.image.value) && !isUrl(value.image_2x_h5.value)) {
            console.log('替换h5图片')
            value.image_2x_h5.value = value.image.value
            value.image_3x_h5.value = value.image_2x.value
          }
        }
      })
    }

    const index = state.components.findIndex(item => item.id === payload.id)
    if (index !== -1) {
      state.components[index].fixed = 1
      state.components[index].reqData = payload.data
      state.components[index].category = payload.data.category
    } else {
      // 没有则新增
      state.components.push({ ...payload, fixed: 1, key: payload.id })
    }
  },
  /**
   *
   * @param {*} state
   * @param {Object} payload {visualKeys: Array, data: *}
   */
  updateByVisualKeys(state, payload) {
    updateByVisualKeys(state, payload)
  },
  addComponent(state, data) {
    // 判断是否有底
    if (state.components.length > 0 && state.components[state.components.length - 1].category === 'Footer') {
      state.components.splice(state.components.length - 1, 0, data)
    } else {
      state.components.push(data)
    }
  },
  updateConfigs(state, configs) {
    state.configs = configs
  },
  deleteComponentById(state, { index }) {
    state.components.splice(index, 1)
  },
  replaceComponentByIndex(state, data) {
    state.components.splice(data.index, 1, data.item)
  },
  updateComponentHeader(state, data) {
    state.components = [data.item, ...state.components]
  },
  updateComponentFooter(state, data) {
    state.components = [...state.components, data.item]
  },
  changeComponentIndex(state, list) {
    if (state.components.length - list.length === 2) {
      // 头尾同时存在
      state.components = [state.components[0], ...list, state.components[state.components.length - 1]]
    } else if (state.components.length - list.length === 1 && state.components[state.components.length - 1].category === 'Footer') {
      // 只有尾存在
      state.components = [...list, state.components[state.components.length - 1]]
    } else if (state.components.length - list.length === 1 && state.components[0].category === 'Header') {
      // 只有头存在
      state.components = [state.components[0], ...list]
    } else if (state.iframe) {
      state.components = [state.components[0], ...list]
    }
  },
  updateUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  updatePageId(state, pageId) {
    state.pageId = pageId
  },
  updateComponentsKey(state, componentsKey) {
    state.componentsKey = componentsKey
  },
  updateDomainData(state, domainData) {
    state.domainData = domainData
  },
  updateIsSupportWebp(state, isSupport) {
    state.isSupportWebp = isSupport
  }
}

export const getters = {
  getComponent(state) {
    /**
     * 获取单个组件的数据
     * @param {String} id
     */
    return (id) => {
      return state.components.find(item => item.id === id)
    }
  },
  getByVisualKeys(state) {
    /**
     * 获取可视化组件的数据
     * @param {Array} visualKeys
     */
    return (visualKeys) => {
      let value

      visualKeys.forEach((key, index) => {
        if (index === 0) {
          value = state.components.find(item => item.id === key).reqData
        } else if (value && value.type) {
          // 是有类型的 有value
          if (value.type === 'List' && value.value[0].key >= 0) {
            value = value.value.find(item => item.key + '' === key + '')
          } else {
            value = value.value[key]
          }
        } else {
          const templateData = Object.assign({}, value[key])
          if (value.clipData) {
            templateData.clipData = value.clipData
          }
          value = templateData
        }
      })

      return value
    }
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { $axios }) {
    // 服务端判断登录
    await $axios.get('/console/user/baseInfo', {
      params: {
        noAuth: true // 未登录不跳转
      }
    }).then((res) => {
      console.debug('login')
      commit('updateUserInfo', res.data.result)
    }).catch(() => {
      console.debug('unLogin')
      commit('updateUserInfo', null)
    })
  },
  getErr({ commit }, errInfo) {
    console.log(errInfo, 'errInfo')
    const { err, hook, url, req } = errInfo
    const headers = (req && req.headers) || {}
    if (process.server) {
      this.$axios.post(`http://127.0.0.1:${process.env.port}/api/getErr`, {
        err,
        hook,
        url
      }, { headers })
    } else {
      this.$axios.post('/getErr', {
        err,
        hook,
        url
      })
    }
  }
}

function updateByVisualKeys(state, payload) {
  let value

  payload.visualKeys.forEach((key, index) => {
    if (Array.isArray(key)) {
      updateByVisualKeys(state, {
        visualKeys: key,
        data: payload.data[index]
      })

      return
    }

    if (index === 0) {
      value = state.components.find(item => item.id === key).reqData
    } else if (index === payload.visualKeys.length - 1) {
      // 更新最后一项
      let updateValue = payload.data
      if (updateValue.type) {
        updateValue = updateValue.value
      }

      if (value[key].type) {
        if (value[key].type === 'Image') {
          if (payload.type === 'clipData') {
            value.clipData = {}
            value.clipData.value = JSON.stringify(payload.clipData)
          } else {
            value[key].value = payload.data.value ? payload.data.value.value : payload.data
          }
          // value[key].value = payload.data.value ? payload.data.value.value : payload.data
        } else {
          // TODO 此处取消与确定传入不一致，需要或判断
          value[key].value = Object.keys(payload.data).indexOf('value') !== -1 ? payload.data.value : payload.data
        }
      } else {
        value[key] = payload.data
      }
    } else if (value && value.type) {
      if (value.type === 'List' && value.value[0].key >= 0) {
        value = value.value.find(item => item.key + '' === key + '')
      } else {
        value = value.value[key]
      }
      // value = value.value[key]
    } else {
      if (value.clipData && !value[key].clipData) {
        if (typeof value.clipData !== 'string') {
          value[key].clipData = value.clipData
        } else {
          value[key].clipData = JSON.parse(value.clipData)
        }
      }
      value = value[key]
    }
  })
}
